/* eslint-disable import/no-dynamic-require */
/* eslint no-console:0 */
const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

require('../stylesheets/application.sass');

require('../../../../../app/webpack/javascript/shared/components/accordion');
require('../../../../../app/webpack/javascript/shared/components/modal');
require('../../../../../app/webpack/javascript/shared/components/burger_menu');
require('../../../../../app/webpack/javascript/shared/slider');

require('../javascript/carte_simple_el');
require('../javascript/filtre_recherche');
require('../javascript/tracking_events');
require('../javascript/homepage');
require('../javascript/navigation_mobile');
require('../javascript/navigation_desktop');
