import onLoadAsync from '../on_load_async';

onLoadAsync(() => {
  const containers = document.querySelectorAll('.js-accordion-container');

  containers.forEach((container, index) => {
    const trigger = container.querySelector('.js-accordion-trigger');

    trigger.addEventListener('click', () => {
      container.classList.toggle('closed');
    });
    // Supprime la classe "closed" de la première ligne
    if (index === 0) {
      container.classList.remove('closed');
    }
    // This avoids triggering the accordion component when clicking a form or a link
    // For example:
    //   - "Download month" button on invoices index (form)
    //   - "Factures" button on sites index (link)
    trigger.querySelectorAll('form, a').forEach((form) => {
      form.addEventListener('click', (e) => e.stopPropagation());
    });
  });
});
