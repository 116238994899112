import isHomepage from './homepage';
import scrollListener from '../../../../../app/webpack/javascript/shared/scroll_listener';
import onLoadAsync from '../../../../../app/webpack/javascript/shared/on_load_async';

const MAIN_NAVBAR_CLASS_WHEN_PAGE_SCROLLED = 'scrolled';
const MAIN_NAVBAR_CLASS_WHEN_MENU_DISPLAYED = 'menu-displayed';

function navBarBackgroundStayVisible(mainNavBar) {
  mainNavBar.classList.add(MAIN_NAVBAR_CLASS_WHEN_MENU_DISPLAYED);
}

function navBarBackgroundCanBeInvisible(mainNavBar) {
  mainNavBar.classList.remove(MAIN_NAVBAR_CLASS_WHEN_MENU_DISPLAYED);
}

/**
 * Return the index of the displayed menu or null if no menu is displayed
 */
function displayedMenuIndex() {
  const currentlyDisplayedMenu = document.querySelector('.navigation-item-menu.active');
  return currentlyDisplayedMenu ? currentlyDisplayedMenu.dataset.navigationItemIndex : null;
}

/**
 * Hide the currently displayed menu
 */
function hideCurrentMenu() {
  document.querySelectorAll('.navigation-item.active').forEach((navigationItem) => {
    navigationItem.classList.remove('active');
  });

  document.querySelectorAll('.navigation-item-menu.active').forEach((navigationItemMenu) => {
    navigationItemMenu.classList.remove('active');
    navigationItemMenu.style.setProperty('bottom', 0);
  });
}

/**
 * When a menu item is clicked
 */
function selectedNavigationItem(navigationItem, mainNavBar) {
  const selectedMenuIndex = navigationItem.dataset.navigationItemIndex;

  if (displayedMenuIndex() === selectedMenuIndex) {
    // We're already displaying the right menu. Nothing to do here
    return;
  }

  hideCurrentMenu();

  navigationItem.classList.add('active');

  // eslint-disable-next-line max-len
  document.querySelectorAll(`.navigation-item-menu-${selectedMenuIndex}`).forEach((navigationItemMenu) => {
    navigationItemMenu.classList.add('active');
    navigationItemMenu.style.setProperty('bottom', `-${navigationItemMenu.clientHeight}px`);
  });

  // Be sure the navbar background stays visible
  navBarBackgroundStayVisible(mainNavBar);
}

onLoadAsync(() => {
  const mainNavBar = document.querySelector('.main-navbar');

  // Avoid error for blocs gallery
  if (!mainNavBar) {
    return;
  }

  // Do stuff when clicking on a link on the main bar
  mainNavBar.querySelectorAll('[data-navigation-item-index]').forEach((navigationItem) => {
    navigationItem.addEventListener('mouseover', () => {
      selectedNavigationItem(navigationItem, mainNavBar);
    });
  });

  document.querySelector('.nav-wrapper').addEventListener('mouseleave', () => {
    hideCurrentMenu();

    navBarBackgroundCanBeInvisible(mainNavBar);
  });

  // Manage the background color of the navigation bar on the homepage
  if (isHomepage()) {
    let navBarHighlighted = false;

    const alertBanner = document.querySelector('.alert-banner');
    const alertBannerClientHeight = alertBanner && alertBanner.clientHeight;

    scrollListener(() => {
      const scrollPosition = window.scrollY;
      if (scrollPosition === 0) {
        // We arrived at the top of the page
        if (navBarHighlighted) {
          navBarHighlighted = false;
          mainNavBar.classList.remove(MAIN_NAVBAR_CLASS_WHEN_PAGE_SCROLLED);
        }
      } else if (scrollPosition > 0) {
        // We left the top of the page
        // The > 0 if for Safari where you can temporary scroll above the top of the page
        if (!navBarHighlighted) {
          navBarHighlighted = true;
          mainNavBar.classList.add(MAIN_NAVBAR_CLASS_WHEN_PAGE_SCROLLED);
        }
      }

      if (alertBannerClientHeight) {
        if (alertBannerClientHeight > scrollPosition) {
          alertBanner.style.marginTop = `-${scrollPosition}px`;
          alertBanner.style.display = 'block';
        } else {
          alertBanner.style.display = 'none';
        }
      }
    });
  }
});
