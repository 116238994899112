import MicroModal from 'micromodal';
import onLoadAsync from '../on_load_async';

onLoadAsync(() => {
  MicroModal.init({
    onShow: (modal) => {
      // We use scrollTop to scroll to the top of the modal's content when opening
      // We use it on 2 div because the height is defined differently on mobile and desktop
      const modalDiv = modal;
      modalDiv.scrollTop = 0;
      const containerDiv = modal.querySelector('.modal-container');
      containerDiv.scrollTop = 0;
    },
    disableScroll: true,
  });

  document.querySelectorAll('.js-modal-open-at-page-load').forEach((element) => {
    const elementId = element.id;
    MicroModal.show(elementId);
  });

  document.querySelectorAll('.modal-checkbox').forEach((popInCheckbox) => {
    popInCheckbox.addEventListener('click', (e) => {
      // This avoids changing the checkbox state when clicking it to open the modal
      e.preventDefault();
    });
  });
});
